import axios from "axios";

const telegram_web_app = window.Telegram.WebApp;

const test_init_data = 'query_id=AAH4HlIXAAAAAPgeUhcg4_m0&user=%7B%22id%22%3A391257848%2C%22first_name%22%3A%22Kiweser%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22kirweser%22%2C%22language_code%22%3A%22ru%22%7D&auth_date=1683022481&hash=d686ef5a1e41e79645a247e7cd4998d1eecb8ed0a14902bad5438e4432c60fb6'
const test_hash = 'd686ef5a1e41e79645a247e7cd4998d1eecb8ed0a14902bad5438e4432c60fb6'

let _init_data;
let _hash;
if (process.env.REACT_APP_DEV_MODE === 'Y') {
    _init_data = test_init_data
    _hash = test_hash
} else {
    _init_data = telegram_web_app.initData
    _hash = telegram_web_app.initDataUnsafe.hash
}

console.log('auth data', _init_data, _hash)

const apiClient = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'x-tg-webapp-init-data': _init_data,
        'x-tg-webapp-hash': _hash,
        'accept': 'application/json'
    },
})

export default apiClient;
