function Loading(props) {

    const {isLoading} = props

    return (
        <div className={`preloader${isLoading ? "" : " hide"} `}>
            <div className="loading">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Loading;