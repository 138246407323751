import './styles/main.scss';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import ScanQR from "./pages/scanQR";
function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/scan_qr" element={<ScanQR/>} />
            </Routes>
        </BrowserRouter>
    )

}

export default App;
