import {useCallback, useEffect, useMemo, useState} from "react";
import DataInputField from "./dataInput_components/inputField";
import apiClient from "../hooks/apiClient";
import Loading from "./Loading";

function ScanQR(props) {

    const tg = useMemo(() => window.Telegram.WebApp, [])

    const [globalLoading, setGlobalLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [tryings, setTryings] = useState(0)

    const parseQRDate = useCallback((dateString) => {
        const year = dateString.slice(0, 4);
        const month = dateString.slice(4, 6) - 1;
        const day = dateString.slice(6, 8);
        const hour = dateString.slice(9, 11);
        const minute = dateString.slice(11, 13);
        const second = dateString.slice(13, 15);

        return new Date(Date.UTC(year, month, day, hour, minute, second))
    }, [])


    const goToManualInput = useCallback(async() => {
        setGlobalLoading(true)
        tg.close()
    }, [])

    const handleTextQR = useCallback(async(qrText) => {
        const postData = {
            data: qrText
        }
        try {
            setGlobalLoading(true)
            const response = await apiClient.post('/receipts/qr', postData)
            tg.close()
            setGlobalLoading(false)
        } catch (e) {
            if (tryings === 3) {
                await goToManualInput()
            }
            setGlobalLoading(false)
            setErrorMessage(e.response.data.detail)
        }
    }, [tryings, goToManualInput, tg])

    const openScanQR = useCallback(() => {
        setErrorMessage('')
        tg.showScanQrPopup({text: ''}, handleTextQR)
    }, [tg, handleTextQR])

    useEffect(() => {
        openScanQR()
    }, [])

    return (
        <>
            <div className="kassa login">
                <h1>Magnum Retail - Розыгрыш</h1>
                <div className="notify">
                    <div className={`error ${(errorMessage.length === 0) ? 'hide' : ''}`}>{errorMessage}</div>
                </div>
                <div className="next">
                    <button
                        className={`next-button open-scan`}
                        onClick={openScanQR}
                    >
                        <span>Отсканировать QR-код</span>
                    </button>
                    <button
                        className={`next-button scan-error`}
                        onClick={goToManualInput}
                    >
                        <span>Закрыть сканнер</span>
                    </button>
                </div>
                <Loading isLoading={globalLoading}/>
            </div>
        </>
    )
}

export default ScanQR;
